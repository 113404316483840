import * as React from "react"

const Logo = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="166px" height="72px" viewBox="0 0 920.000000 400.000000"
         preserveAspectRatio="xMidYMid meet">
        <title>LC-Studio</title>

            <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M5684 3711 c-113 -6 -129 -12 -166 -56 -16 -19 -18 -110 -18 -1449
l0 -1429 35 -32 c32 -31 40 -34 127 -40 51 -3 692 -5 1423 -3 1252 3 1332 4
1358 21 15 9 35 29 44 44 17 26 18 107 18 1433 0 773 -3 1417 -8 1432 -4 15
-24 40 -44 55 l-36 28 -1311 1 c-721 1 -1361 -1 -1422 -5z m575 -291 c64 -32
108 -77 141 -142 l30 -61 0 -792 c0 -772 1 -793 20 -817 l19 -25 758 -7 c728
-7 760 -8 800 -27 55 -27 120 -91 144 -142 23 -52 26 -181 5 -231 -20 -49 -82
-113 -141 -147 l-50 -29 -791 0 -790 0 -72 24 c-188 64 -322 175 -407 337 -73
139 -70 96 -69 1039 l0 845 22 40 c61 113 160 170 279 161 34 -3 80 -14 102
-26z m1329 -36 c151 -37 314 -134 418 -248 114 -124 151 -217 125 -313 -27
-98 -133 -183 -230 -183 -93 0 -119 14 -221 120 -122 127 -158 145 -290 145
-88 0 -101 -3 -162 -33 -78 -38 -120 -82 -161 -171 -79 -167 -3 -368 171 -453
59 -29 76 -33 147 -33 110 1 170 29 278 133 104 100 133 115 217 116 109 1
201 -61 229 -153 16 -53 14 -145 -4 -187 -50 -120 -243 -284 -404 -345 -125
-46 -171 -54 -326 -53 -141 0 -156 2 -245 32 -136 45 -239 109 -340 211 -101
102 -166 207 -211 341 -30 90 -32 103 -32 245 -1 179 11 234 83 380 119 242
327 401 600 460 81 18 264 12 358 -11z"/>
                <path d="M1741 3389 c-107 -10 -190 -49 -270 -128 -170 -166 -159 -342 39
-611 39 -52 70 -96 70 -98 0 -2 -24 0 -53 3 -68 9 -124 -6 -211 -56 -48 -27
-72 -36 -76 -28 -4 6 -10 37 -14 68 -13 108 -66 352 -86 391 -11 21 -29 41
-40 45 -26 8 -87 -23 -114 -58 -23 -31 -20 -55 34 -362 40 -225 60 -441 60
-647 0 -238 -9 -253 -173 -298 -242 -66 -297 -84 -362 -118 -128 -67 -220
-182 -202 -254 29 -116 221 -164 405 -102 42 14 109 44 147 66 98 58 222 184
274 280 30 54 49 78 61 78 117 0 445 -40 590 -73 156 -34 300 -26 300 18 0 50
-314 116 -651 138 -103 7 -191 16 -196 21 -15 15 -8 143 10 170 10 14 22 26
27 26 5 0 30 -19 57 -42 62 -53 148 -93 245 -112 100 -21 169 -20 295 1 l102
17 28 -26 c40 -39 109 -66 197 -79 140 -21 284 20 321 91 39 75 5 150 -140
311 -55 61 -102 116 -103 121 -2 5 44 7 109 6 l113 -3 23 -60 c12 -33 39 -105
59 -160 20 -54 47 -116 60 -137 21 -32 29 -38 60 -38 47 0 103 27 116 56 8 18
6 35 -12 79 -12 31 -20 58 -16 62 3 3 32 -13 65 -35 92 -65 104 -67 188 -41
l73 23 23 -22 c39 -37 108 -152 94 -157 -6 -2 -97 -22 -202 -44 -713 -150
-1122 -257 -1540 -404 -588 -205 -674 -261 -675 -436 0 -62 1 -65 40 -102 61
-58 154 -84 300 -84 130 0 177 13 186 52 4 13 2 29 -4 35 -6 6 -58 15 -115 19
-116 8 -149 20 -196 74 -40 46 -39 76 3 123 59 64 245 145 641 276 608 203
1031 303 1670 397 270 39 438 48 482 25 42 -21 81 -20 134 3 79 35 198 46 484
46 286 0 315 -4 315 -46 0 -42 -31 -86 -78 -112 -94 -51 -332 -126 -732 -231
-184 -48 -519 -134 -550 -142 -76 -18 -282 -79 -330 -96 -141 -52 -117 -68
230 -152 210 -50 289 -77 316 -107 16 -18 19 -37 19 -113 l0 -91 57 0 c88 1
128 50 128 160 0 113 -62 169 -237 215 -43 12 -106 31 -141 43 -90 30 -81 45
43 72 55 12 152 36 215 53 63 17 174 46 245 66 217 58 645 183 732 214 210 74
327 199 279 299 -20 41 -72 67 -166 82 -90 15 -170 14 -508 -6 -179 -10 -296
-13 -301 -7 -13 13 -122 380 -180 604 -58 226 -109 456 -146 661 -45 253 -62
287 -131 275 -20 -4 -48 -19 -62 -33 -25 -25 -25 -27 -19 -141 4 -86 22 -194
65 -404 32 -159 55 -288 50 -288 -5 0 -32 17 -61 38 -80 59 -142 92 -199 108
-137 36 -227 -5 -247 -111 -6 -32 -18 -56 -33 -68 -59 -49 -256 -127 -317
-127 -42 0 -58 29 -111 194 -64 199 -82 226 -148 226 -50 0 -59 23 -71 160
-15 193 -67 321 -166 408 -82 72 -286 110 -494 91z m249 -141 c88 -22 151
-117 186 -278 18 -87 18 -316 -1 -491 -9 -77 -13 -149 -10 -160 9 -26 77 -26
120 1 47 29 65 63 76 138 11 75 13 79 33 63 25 -21 93 -255 78 -265 -15 -9
-122 -25 -212 -31 l-96 -7 -153 158 c-376 388 -503 601 -446 750 43 114 234
168 425 122z m1397 -856 c74 -62 150 -145 226 -247 59 -80 60 -80 33 -83 -14
-2 -51 2 -81 8 -81 17 -141 75 -198 193 -46 94 -69 161 -59 171 11 11 22 6 79
-42z m-1879 -17 c16 -13 46 -56 66 -94 51 -98 88 -119 163 -90 37 14 43 25 43
81 0 26 4 49 9 52 9 6 58 -36 247 -209 60 -55 136 -123 169 -150 75 -63 89
-79 115 -134 23 -48 23 -79 -1 -94 -22 -14 -132 38 -186 87 -47 42 -56 43
-228 20 -146 -19 -219 -14 -289 20 -143 70 -257 283 -226 421 25 110 61 138
118 90z m1535 -81 c6 -33 2 -272 -5 -279 -3 -3 -18 18 -33 47 -73 142 -150
182 -237 123 -45 -30 -52 -31 -38 -5 13 24 85 63 200 106 106 40 107 40 113 8z
m595 -373 c91 -9 114 -22 138 -80 8 -18 14 -37 14 -42 0 -17 -40 -28 -154 -43
-150 -20 -158 -16 -209 88 -20 42 -37 78 -37 81 0 7 153 5 248 -4z m-2622
-385 c19 -49 -158 -258 -261 -307 -62 -30 -130 -38 -165 -19 -46 24 -48 138
-3 190 37 45 121 87 224 114 148 39 197 44 205 22z"/>
                <path d="M3893 3110 c-30 -18 -63 -65 -63 -90 0 -29 34 -79 61 -90 43 -18 114
-5 138 26 29 37 15 100 -32 152 -25 27 -62 28 -104 2z"/>
                <path d="M4330 2899 c-72 -22 -85 -56 -95 -249 -12 -236 53 -424 198 -570 63
-65 127 -100 192 -107 46 -6 127 27 173 70 100 93 105 244 14 456 -84 194
-189 334 -286 380 -53 26 -145 35 -196 20z m159 -196 c68 -82 159 -297 182
-428 10 -56 9 -69 -5 -90 -22 -33 -48 -31 -86 6 -66 67 -133 246 -149 400 -5
51 -7 99 -5 106 2 7 6 20 9 28 7 24 21 18 54 -22z"/>
                <path d="M3941 2654 c-19 -15 -31 -35 -31 -50 0 -72 128 -488 189 -618 35 -73
56 -89 116 -90 64 -1 95 11 95 36 0 12 -36 136 -81 276 -129 407 -159 472
-224 472 -21 0 -44 -9 -64 -26z"/>
                <path d="M5912 619 c-66 -33 -96 -85 -100 -174 -6 -112 38 -181 131 -206 85
-23 171 13 206 85 29 59 27 66 -19 66 -35 0 -41 -4 -55 -34 -11 -22 -28 -39
-47 -45 -44 -16 -53 -14 -87 15 -58 49 -65 144 -15 204 22 25 33 30 70 30 37
0 47 -5 66 -30 17 -24 29 -30 60 -30 28 0 38 4 38 16 0 32 -41 83 -84 103 -54
27 -110 27 -164 0z"/>
                <path d="M6508 620 c-48 -26 -68 -56 -68 -103 0 -51 29 -79 116 -107 86 -28
114 -45 114 -70 0 -25 -29 -40 -75 -40 -27 0 -44 8 -67 31 -31 31 -90 50 -101
32 -3 -5 3 -28 13 -50 21 -48 59 -71 128 -80 105 -12 182 39 182 121 0 61 -32
90 -136 121 -92 29 -110 48 -72 78 31 25 86 16 111 -18 26 -35 87 -36 87 -2 0
79 -146 134 -232 87z"/>
                <path d="M8217 626 c-76 -28 -117 -95 -117 -192 0 -111 59 -185 159 -199 88
-12 161 26 202 105 16 32 16 134 0 182 -22 65 -104 119 -181 118 -14 -1 -42
-7 -63 -14z m138 -92 c60 -65 37 -205 -37 -225 -60 -16 -109 15 -129 80 -15
50 -5 103 27 141 22 25 33 30 70 30 36 0 50 -5 69 -26z"/>
                <path d="M5500 435 l0 -195 135 0 135 0 0 35 0 35 -90 0 -90 0 0 160 0 160
-45 0 -45 0 0 -195z"/>
                <path d="M6770 618 c1 -49 11 -58 66 -58 l54 0 0 -160 0 -160 45 0 45 0 0 160
0 160 55 0 55 0 0 35 0 35 -160 0 c-121 0 -160 -3 -160 -12z"/>
                <path d="M7140 481 c0 -137 2 -151 23 -182 35 -53 85 -73 163 -64 77 8 118 37
134 93 5 20 10 97 10 170 l0 132 -45 0 -45 0 0 -144 c0 -118 -3 -147 -16 -160
-24 -25 -58 -29 -98 -12 l-36 15 0 150 0 151 -45 0 -45 0 0 -149z"/>
                <path d="M7550 435 l0 -195 91 0 c49 0 110 5 135 11 74 19 114 86 114 191 0
60 -14 95 -53 136 -40 41 -80 52 -194 52 l-93 0 0 -195z m207 100 c13 -9 28
-27 33 -41 13 -34 13 -111 -1 -136 -14 -26 -68 -48 -119 -48 l-40 0 0 126 0
127 51 -6 c28 -4 62 -13 76 -22z"/>
                <path d="M7950 435 l0 -195 45 0 45 0 0 195 0 195 -45 0 -45 0 0 -195z"/>
                <path d="M6220 390 l0 -30 80 0 80 0 0 30 0 30 -80 0 -80 0 0 -30z"/>
            </g>
    </svg>

)

export default Logo